<!--
 * @Description: 市场部导出，分销报备数据
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-06-13 15:51:02
-->
<template>
    <el-card class="box query">
      <el-form :model="query" ref="query" :inline="true" @submit.prevent="onSearch">
        <el-form-item label="单号">
          <el-input
            v-model="query.no"
            placeholder="报备单号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="客户">
          <el-input
            v-model="query.q"
            placeholder="姓名、手机号"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="报备人">
          <el-input
            v-model="query.consumer"
            placeholder="报备人姓名、手机号"
            style="width: 220px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="带看人">
          <el-input
            v-model="query.daikan"
            placeholder="带看人姓名、手机号"
            style="width: 220px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报备日期">
          <el-date-picker
            v-model="query.dates"
            type="daterange"
            value-format="YYYY-MM-DD"
            unlink-panels
            :clearable="false"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="状态">
          <el-select
            v-model="query.status"
            placeholder
            clearable
            style="width: 150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in enums.report_status"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="公司分类">
          <el-select
            v-model="query.is_deyou"
            placeholder
            clearable
            style="width: 150px"
          >
            <el-option label="德佑" :value="true"></el-option>
            <el-option label="分销" :value="false"></el-option>
          </el-select>
        </el-form-item> -->
  
        <!-- <el-form-item label="处理人">
          <el-input v-model="query.modify" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="客服处理">
          <el-select
            v-model="query.isAc"
            placeholder
            clearable
            style="width: 100px"
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服姓名">
          <el-input v-model="query.kf" placeholder="姓名"></el-input>
        </el-form-item>
  
        <el-form-item label="有附件">
          <el-select
            v-model="query.isAttrs"
            placeholder
            clearable
            style="width: 100px"
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
   -->
        <el-form-item>
          <el-button type="primary" round @click="onSearch" icon="search" native-type="submit"
            >查询</el-button
          >
  
          <el-tooltip
            class="box-item"
            effect="dark"
            content="下载查询结果数据"
            placement="top"
          >
            <el-button
              icon="Download"
              v-power="'admin/v1/reportAnalysis/fx/export_excel'"
              @click="downloadExcel"
              :loading="downloading"
              >下载数据</el-button
            >
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div></div>
    </el-card>
  
    <el-card class="box data" v-loading="loading">
      <el-table :data="tableData.data" border stripe>
        <el-table-column label="编号" prop="id" width="100"></el-table-column>
        <!-- <el-table-column
          label="单号"
          prop="hNumber"
          width="120"
        ></el-table-column> -->
        <el-table-column
          label="公司名称"
          prop="company"
          width="300"
        ></el-table-column>
        <el-table-column
          label="项目"
          prop="estateName"
          width="200"
        ></el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="scope">
            <el-link
              type="warning"
              :underline="false"
              v-if="scope.row.curStatus == 0"
              >{{ scope.row.cur_status_text }}</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              v-else-if="scope.row.curStatus == 1"
              >{{ scope.row.cur_status_text
              }}{{ scope.row.unknown ? "(结果未知)" : "" }}</el-link
            >
            <el-link
              type="success"
              :underline="false"
              v-else-if="scope.row.curStatus == 2"
              >{{ scope.row.cur_status_text }}</el-link
            >
            <el-link
              type="danger"
              :underline="false"
              v-else-if="scope.row.curStatus == 3"
              >{{ scope.row.cur_status_text }}</el-link
            >
            <el-link :underline="false" v-else>{{
              scope.row.cur_status_text
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="客户姓名" prop="clientName" width="100">
        </el-table-column>
        <!-- <el-table-column label="客户手机" prop="clientMobile" width="120">
        </el-table-column> -->
        <el-table-column label="客户性别" prop="sex" width="90">
        </el-table-column>
        <el-table-column label="带看人" prop="daikanName" width="100">
        </el-table-column>
        <el-table-column label="带看人手机" prop="daikanMobile" width="120">
        </el-table-column>
        <el-table-column label="报备人" prop="consumer_Name" width="100">
        </el-table-column>
        <el-table-column label="报备人手机" prop="consumer_Mobile" width="140">
        </el-table-column>
        <el-table-column
          label="到案人数"
          prop="dueNum"
          width="90"
        ></el-table-column>
        <el-table-column
          label="到案时间"
          prop="dueTime"
          width="180"
        ></el-table-column>
        <!-- <el-table-column
          label="报备备注"
          prop="comment"
          width="300"
        ></el-table-column> -->
        <el-table-column
          label="报备时间"
          prop="creationTime"
          width="180"
        ></el-table-column>
        <el-table-column label="专员">
          <template #default="scope">
            {{ scope.row.counselors.map(x=>x.name).join('、') }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="处理人" width="100">
          <template #default="scope">
            {{ scope.row.isAcData ? scope.row.kefu_name : scope.row.modify_name }}
          </template>
        </el-table-column>
        <el-table-column label="处理时间" prop="modifiedTime" width="180">
          <template #default="scope">
            {{ scope.row.isAcData ? scope.row.acTime : scope.row.modifiedTime }}
          </template>
        </el-table-column>
        <el-table-column
          label="处理结果"
          prop="resultContent"
          min-width="300"
        ></el-table-column> -->
      </el-table>
      <!-- <el-scrollbar>
          <div class="grid">
            <div class="item" v-for="(item, index) in tableData.data" :key="index">
              <el-card shadow="hover">
                <el-descriptions :column="1">
                  
                   
                  <el-descriptions-item label="处理人"
                    >{{ item.modify_name }}{{ item.modifiedTime }}
    
                    <span v-if="item.isAcData"
                      >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="处理结果">{{
                    item.resultContent
                  }}</el-descriptions-item>
                </el-descriptions>
              </el-card>
            </div>
          </div>
        </el-scrollbar> -->
  
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
      ></el-pagination>
    </el-card>
  </template>
  
  <script>
  import common_api from "@/http/common_api";
  import fileDownload from "js-file-download";
  
  export default {
    data() {
      return {
        loading: false,
        downloading: false,
        query: {
          pageIndex: 1,
          pageSize: 20, 
          sort: {
            order: "",
            prop: "",
          },
          q: "",
        },
        enums: {},
        tableData: {
          counts: 0,
        },
      };
    },
    created() {
  
      this.query.dates =  [this.$moment().add(-1,'months').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
  
      common_api.get_enums().then((res) => {
        if (res.code == 0) {
          this.enums = res.data;
        }
      });
      this.loadData();
    },
    methods: {
      /**
       * 搜索
       */
      onSearch() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      /**
       * 分页页数更改
       */
      pageSizeChange(val) {
        this.query.pageSize = val;
        this.loadData();
      },
  
      /**
       * 分页页码更改
       */
      pageCurrentChange(val) {
        this.query.pageIndex = val;
        this.loadData();
      },
  
      /**
       *
       */
      loadData() {
        this.loading = true;
        this.$http
          .post("/admin/v1/reportAnalysis/fx", this.query)
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.data;
            }
          })
          .finally(() => (this.loading = false));
      },
  
      /**
       * 下载数据
       */
      downloadExcel() {
        this.downloading = true;
        var arg = Object.assign({}, this.query);
        delete arg.storeIds;
        if (
          Array.isArray(this.query.storeIds) &&
          this.query.storeIds.length > 0
        ) {
          arg.store_ids = this.query.storeIds.map((x) => {
            return x[1];
          });
        }
        this.$http
          .post("admin/v1/reportAnalysis/fx/export_excel", arg, {
            responseType: "blob",
          })
          .then((res) => {
            fileDownload(res, "分销报备数据.xlsx");
          })
          .finally(() => {
            this.downloading = false;
          });
      },
    },
  };
  </script>
 